<template>
  <div class="customize-main">
    <table
      class="customize-form"
      v-if="departmentList && companyList && departmentList && projectList"
      border="0"
      cellpadding="0"
      cellspacing="0"
    >
      <tbody>
        <tr>
          <td class="text-right" colspan="4">
            <p>申请日期 (Applying Date)</p>
          </td>
          <td class="text-left">
            <p>2024/05/06</p>
          </td>
        </tr>

        <tr>
          <td class="text-center" colspan="5">
            <h1>
              中东公司休假或沙特境内动迁审批表<br />BSNEI Employee Vacation
              （Business Travel）Application
            </h1>
          </td>
        </tr>

        <tr>
          <td class="text-center" colspan="2">
            <div class="name">项目部<br />Project</div>
          </td>
          <td class="text-center">
            <div class="name">
              <el-input></el-input>
            </div>
          </td>
          <td class="text-center">
            <div class="name">单位信息<br />Unit/Dept.</div>
          </td>
          <td class="text-center">
            <div class="name">
              <el-input></el-input>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            <div class="name">申请人姓名<br />Full Name</div>
          </td>
          <td class="text-center">
            <div class="name">
              <el-input></el-input>
            </div>
          </td>
          <td class="text-center">
            <div class="name">护照号<br />Passport No.</div>
          </td>
          <td class="text-center">
            <div class="name">
              <el-input></el-input>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            <div class="name">暂住证号<br />IQAMA No.</div>
          </td>
          <td class="text-center">
            <div class="name">
              <el-input></el-input>
            </div>
          </td>
          <td class="text-center">
            <div class="name">SPONSOR</div>
          </td>
          <td class="text-center">
            <div class="name">
              <el-input></el-input>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            <div class="name">最近一次赴沙时间<br />Last vacation period</div>
          </td>
          <td class="text-center">
            <div class="name">
              <el-date-picker
                v-model="daterange"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
          </td>
          <td class="text-center">
            <div class="name">岗位职务<br />Position</div>
          </td>
          <td class="text-center">
            <div class="name">
              <el-input></el-input>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            <div class="name">休假类型<br />Leave Type</div>
          </td>
          <td class="text-left">
            <div class="flex">
              <div>
                <el-checkbox></el-checkbox>
                <span>休假Vacation</span>
              </div>
              <div>
                <el-checkbox></el-checkbox>
                <span>紧急事件Others</span>
              </div>
            </div>
          </td>
          <td class="text-center">
            <div class="name">理论休假时间<br />Deserved Vacation Period</div>
          </td>
          <td class="text-center">
            <div class="name">
              <el-date-picker
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            <div class="name">计划动迁时间及期限<br />Vacation Plan</div>
          </td>
          <td class="text-center">
            <div class="name">
              <el-date-picker
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </td>
          <td class="text-center">
            <div class="name">本次休假天数（含路程）<br />Vacation Days</div>
          </td>
          <td class="text-center">
            <div class="name">
              <el-input></el-input>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            <div class="name">工作任务及其交接人<br />Vacation Plan</div>
          </td>
          <td class="text-center">
            <div class="name">
              <el-input></el-input>
            </div>
          </td>
          <td class="text-center">
            <div class="name">工作交接人签字<br />Handover Sign.</div>
          </td>
          <td class="text-center">
            <div class="name">
              <el-input></el-input>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            <div class="name">
              （国内）联系方式<br />Contact No., E-mail and Home Address
            </div>
          </td>
          <td class="text-center" colspan="3">
            <div class="name">
              <el-input></el-input>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            <div class="name">申请人直接主管意见<br />Supervisor Approval</div>
          </td>
          <td class="text-center">
            <div class="name">
              <el-input></el-input>
            </div>
          </td>
          <td class="text-center">
            <div class="name">本单位领导意见<br />Unit Manager Approval</div>
          </td>
          <td class="text-center">
            <div class="name">
              <el-input></el-input>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center" rowspan="3">
            <div class="name">项目部<br />Project</div>
          </td>
          <td class="text-center">
            <div class="name">财务部门意见<br />Financial Dept. Approval</div>
          </td>
          <td class="text-center" colspan="3">
            <div class="name">
              <el-input></el-input>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            <div class="name">
              行政部门意见<br />Administrative Dept. Approval
            </div>
          </td>
          <td class="text-center" colspan="3">
            <div class="name">
              <el-input></el-input>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            <div class="name">项目经理意见<br />Project Manager Approval</div>
          </td>
          <td class="text-center" colspan="3">
            <div class="name">
              <el-input></el-input>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center" rowspan="2">
            <div class="name">中东公司<br />SNEMEC</div>
          </td>
          <td class="text-center">
            <div class="name">人力资源部意见<br />HR Dept. Approval</div>
          </td>
          <td class="text-center" colspan="3">
            <div class="name">
              <el-input></el-input>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            <div class="name">
              中东公司领导批准<br />PSNEMEC Manager Approval
            </div>
          </td>
          <td class="text-center" colspan="3">
            <div class="name">
              <el-input></el-input>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            <div class="name">备注<br />Remark</div>
          </td>
          <td class="text-center">
            <div class="name">
              机票费用承担<br />Ticket Provided by company or on own expense
            </div>
          </td>
          <td class="text-center">
            <div class="name">
              <el-input></el-input>
            </div>
          </td>
          <td class="text-center">
            <div class="name">其他<br />With or without Vacation Salary</div>
          </td>
          <td class="text-center">
            <div class="name">
              <el-input></el-input>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            <div class="name">
              航空票务信息及接送交通安排<br />Ticket Information and
              Transportation Arrangement
            </div>
          </td>
          <td class="text-left" colspan="4">
            <div class="name">
              <span>去程:</span>
              <input placeholder="离开城市" />
              <span class="span-left">-</span>
              <input placeholder="到达城市" />
              <span class="span-left">回程:</span>
              <input placeholder="离开城市" />
              <span class="span-left">-</span>
              <input placeholder="到达城市" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import {
  addLeaveApplication,
  editLeaveApplication,
  getProjectAll,
  getDepartmentAll,
  allPosition,
  getCompanyAll,
} from "@/api/user";
export default {
  filters: {
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : null;
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : null;
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : null;
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
  },
  props: {
    CreateDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      daterange: null,
      projectList: [],
      departmentList: [],
      positionList: [],
      companyList: [],
      ID: null,
      KeyID: null,
      EmployeeID: null,
      EmployeeCode: null,
      EmployeeName: null,
      JobProfession: null,
      JobProfessionName: null,
      ProjectID: null,
      ProjectName: null,
      CompanyID: null,
      WorkingUnit: null,
      DepartmentID: null,
      WorkingDepartment: null,
      Note: null,
      EditState: 1,
      ApplicationNumber: null,
      detailsItem: {
        ID: null,
        KeyID: null,
        EmployeeLeaveApplicationKeyID: null,
        TypeID: null,
        TypeName: null,
        StartDate: null,
        EndDate: null,
        NumberOfLeaveDays: null,
        Remark: null,
      },
      Attachments: null,
      options: [
        {
          name: "病假",
          nameEn: "Sick Leave",
          allName: "病假(Sick Leave)",
          value: 1,
        },
        {
          name: "事假",
          nameEn: "Personal Leave",
          allName: "事假(Personal Leave)",
          value: 2,
        },
        {
          name: "工伤",
          nameEn: "Job Injuries",
          allName: "工伤(Job Injuries)",
          value: 3,
        },
        {
          name: "婚假",
          nameEn: "Marriage Leave",
          allName: "婚假(Marriage Leave)",
          value: 4,
        },
        {
          name: "丧假",
          nameEn: "Bereavement Leave",
          allName: "丧假(Bereavement Leave)",
          value: 5,
        },
        {
          name: "护理假",
          nameEn: "Child Birth Leave",
          allName: "护理假(Child Birth Leave)",
          value: 6,
        },
        {
          name: "朝觐",
          nameEn: "Hajj Leave",
          allName: "朝觐(Hajj Leave)",
          value: 7,
        },
        {
          name: "育儿假",
          nameEn: "Parental Leave",
          allName: "育儿假(Parental Leave)",
          value: 8,
        },
        {
          name: "独生子女护理假",
          nameEn: "Only Child Care Leave",
          allName: "独生子女护理假(Only Child Care Leave)",
          value: 9,
        },
      ],
      pickerOptionsStart: {
        disabledDate: (time) => {
          if (this.detailsItem.EndDate) {
            return (
              time.getTime() > new Date(this.detailsItem.EndDate).getTime() ||
              time.getTime() <=
                new Date(this.detailsItem.StartDate).getTime() - 86400000
            );
          }
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      pickerOptionsEnd: {
        disabledDate: (time) => {
          if (this.detailsItem.StartDate) {
            return (
              time.getTime() < new Date(this.detailsItem.StartDate).getTime()
            );
          }
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
    };
  },
  computed: {
    outGoingDuration() {
      let timer = null;
      if (this.detailsItem.StartDate && this.detailsItem.EndDate) {
        timer = this.compareDates(
          this.detailsItem.StartDate,
          this.detailsItem.EndDate
        );
      }
      this.detailsItem.NumberOfLeaveDays = timer;
      return timer ? timer + "天" : null;
    },
  },
  methods: {
    //创建时接收数据
    getCreateData(data1, data2, data3, data4) {},
    //判断选中请假类型
    changeTypeID() {
      this.options.forEach((element) => {
        if (element.value === this.detailsItem.TypeID) {
          this.detailsItem.TypeName = element.allName;
        }
      });
      console.log("11111", this.detailsItem);
    },
    //判断日期是否有误
    changeDate(e) {
      console.log("e", e);
      if (e) {
        let currentTime = new Date(),
          year = currentTime.getFullYear(),
          month =
            currentTime.getMonth() + 1 < 10
              ? "0" + (currentTime.getMonth() + 1)
              : currentTime.getMonth() + 1,
          day =
            currentTime.getDate() < 10
              ? "0" + currentTime.getDate()
              : currentTime.getDate();
        let nowDate = year + "-" + month + "-" + day;

        var currentDate = new Date(nowDate);
        // 获取待比较的日期
        var compareDate = new Date(e);
        if (compareDate >= currentDate) {
          console.log("日期大于当前日期");
        } else {
          this.$message.error("开始日期不能小于当前日期");
          this.detailsItem.StartDate = null;
        }

        if (this.detailsItem.EndDate) {
          var compareEndDate = new Date(this.detailsItem.EndDate);
          if (compareEndDate <= compareDate) {
            this.$message.error("开始日期不能大于或等于结束日期");
            this.detailsItem.StartDate = null;
          }
        }
      }
    },
    //计算两个日期相差天数
    compareDates(date1, date2) {
      // 将字符串转换为Date对象
      var d1 = new Date(date1);
      var d2 = new Date(date2);

      // 获取时间戳（单位：毫秒）
      var timeDiff = Math.abs(d2.getTime() - d1.getTime());

      // 计算天数差
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      return diffDays + 1;
    },
    //保存
    addManpowerRequest(type) {
      if (!this.detailsItem.TypeID) {
        this.$message.warning("请选择请假类型");
        return;
      }
      if (!this.detailsItem.StartDate) {
        this.$message.warning("请选择开始日期");
        return;
      }
      if (!this.detailsItem.EndDate) {
        this.$message.warning("请选择结束日期");
        return;
      }
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        EmployeeID: this.EmployeeID,
        EmployeeCode: this.EmployeeCode,
        EmployeeName: this.EmployeeName,
        JobProfession: this.JobProfession,
        JobProfessionName: this.JobProfessionName,
        ProjectID: this.ProjectID,
        ProjectName: this.ProjectName,
        CompanyID: this.CompanyID,
        WorkingUnit: this.WorkingUnit,
        DepartmentID: this.DepartmentID,
        WorkingDepartment: this.WorkingDepartment,
        Note: this.Note,
        EditState: type,
        Attachments: this.Attachments,
        Details: [],
      };
      data.Details.push(this.detailsItem);
      if (!data.ID) {
        addLeaveApplication(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      } else {
        editLeaveApplication(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      }
    },

    clearData() {
      this.ID = null;
      this.KeyID = null;
      this.EmployeeID = null;
      this.EmployeeCode = null;
      this.EmployeeName = null;
      this.JobProfession = null;
      this.JobProfessionName = null;
      this.ProjectID = null;
      this.ProjectName = null;
      this.CompanyID = null;
      this.WorkingUnit = null;
      this.DepartmentID = null;
      this.WorkingDepartment = null;
      this.Note = null;
      this.Attachments == null;
      this.WorkingDepartment = null;
      this.detailsItem = {
        ID: null,
        KeyID: null,
        EmployeeLeaveApplicationKeyID: null,
        TypeID: null,
        TypeName: null,
        StartDate: null,
        EndDate: null,
        NumberOfLeaveDays: null,
        Remark: null,
      };
    },
    //编辑回显
    getEditData(data) {
      console.log("data", data);
      this.ID = data.ID;
      this.KeyID = data.KeyID;
      this.EmployeeID = data.EmployeeID;
      this.EmployeeCode = data.EmployeeCode;
      this.EmployeeName = data.EmployeeName;
      this.JobProfession = data.JobProfession;
      this.ProjectID = data.ProjectID;
      this.CompanyID = data.CompanyID;
      this.DepartmentID = data.DepartmentID;
      this.ApplicationNumber = data.ApplicationNumber;
      data.Details[0].EndDate = data.Details[0].EndDate.slice(0, 10);
      data.Details[0].StartDate = data.Details[0].StartDate.slice(0, 10);
      this.detailsItem = data.Details[0];
      this.Attachments = data.Attachments;
    },
  },
  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.right-title {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}
.customize-main {
  width: 100%;
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      padding-left: 5px;
      padding-right: 5px;
    }
    .text-center {
      text-align: center;
      .tbale-img1 {
        width: 200px;
      }
      h1 {
        font-size: 28px;
        color: #000;
        margin: 10px 0;
        font-weight: 500;
      }
      p {
        font-size: 12px;
        margin: 10px 0;
      }
      .name {
        font-size: 12px;
        margin: 10px 0;
        color: #000;
      }
    }
    .text-left {
      text-align: left;
      .flex {
        display: flex;
        font-size: 12px;
        color: #000;
        align-items: center;
        margin: 10px 0;
        .el-checkbox {
          font-size: 18px;
          color: #000;
          //   margin-right: 10px;
        }
        div {
          margin-right: 10px;
        }
        .input-box {
          width: 100px;
          border-bottom: 1px solid #000;
          margin: 0 10px;
        }
      }
      .text-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          width: 50%;
        }
        .sign-box {
          display: flex;
          align-items: center;
        }
      }
      .name {
        font-size: 12px;
        margin: 10px 0;
        color: #000;
        display: flex;
        align-items: center;
        input {
          border: none;
          font-size: 16px;
          width: 70px;
          outline: none;
        }
        span {
          margin-right: 20px;
        }
        .span-left {
          margin-left: 10px;
        }
      }
      .el-checkbox {
        margin-right: 0;
      }
      p {
        font-size: 12px;
        margin: 10px 0;
      }
    }
    .text-right {
      text-align: right;
      p {
        font-size: 12px;
        margin: 10px 0;
      }
    }
    .text-size {
      font-size: 16px;
      color: #000;
      text-align: center;
      max-width: 200px;
      .el-checkbox {
        margin: 10px 0;
      }
      span {
        margin-right: 20px;
      }
      div {
        margin: 10px 0;
      }
      .input-box {
        display: inline-block;
        width: 100px;
        border-bottom: 1px solid #000;
        margin: 10px 0;
      }
    }
  }
  .customize-form2 {
    border-top: none;
  }
  .el-select {
    width: 100%;
  }
  .el-date-editor {
    width: 100%;
  }
}
.note-box {
  width: 100%;
  text-align: left;
  font-size: 13px;
}
.name-small {
  font-weight: normal !important;
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.el-checkbox >>> .el-checkbox__inner {
  background-color: #fff !important;
  border-color: #000 !important;
}
.el-checkbox >>> .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border-color: #000;
}
.sign {
  width: 100%;
  max-width: 100px;
}
</style>